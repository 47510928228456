import React from 'react';
import classNames from 'classnames';
import { trackFeatureUsage } from '@animoto/utils/analytics';
// TODO REB-95 Clean up components in web2
import {
  Button as ButtonImport,
  HeaderExtraSmall as HeaderExtraSmallImport,
  ToastPanel as ToastPanelImport,
  toastPanelAlignments
} from '@animoto/components';
import evaluateFeature from '@animoto/ld-utils/evaluateFeature';

import {
  button,
  content,
  text
} from './OwnerToast.module.css';

export default function OwnerToast() {
  const isRebrand = evaluateFeature('rebrand', false);
  const Button = ButtonImport(isRebrand);
  const HeaderExtraSmall = HeaderExtraSmallImport(isRebrand);
  const ToastPanel = ToastPanelImport(isRebrand);

  const onClickButton = () => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'owner-share-toast',
      action  : 'click:share-page'
    });
  };

  const buttonClasses = classNames('s-toast-button', button);
  return (
    <ToastPanel
      alignment={toastPanelAlignments.RIGHT}
      isDismissable={false}
      isOpen
    >
      <div className={content}>
        <HeaderExtraSmall className={text}>This is the video page your viewers will see.</HeaderExtraSmall>
        <Button
          className={buttonClasses}
          href={`/share/${window.location.href.split('/').pop()}`}
          onClick={onClickButton}
        >
          Share Video
        </Button>
      </div>
    </ToastPanel>
  );
}
