// extracted by mini-css-extract-plugin
var _1 = "VideoSteps-module_button__P2T57";
var _2 = "VideoSteps-module_container__A13TL";
var _3 = "VideoSteps-module_desktop__q1AnY";
var _4 = "VideoSteps-module_divider__OECE0";
var _5 = "VideoSteps-module_header__sSelt";
var _6 = "VideoSteps-module_mobile__mjCms";
var _7 = "VideoSteps-module_mobileStepNumber__m_z8v";
var _8 = "VideoSteps-module_number__Dv_Ga";
var _9 = "VideoSteps-module_number1__GEIGj";
var _a = "VideoSteps-module_number2__xLSex";
var _b = "VideoSteps-module_number3__hnMOu";
var _c = "VideoSteps-module_numberContainer__C0VzL";
var _d = "VideoSteps-module_step__nM_EB";
var _e = "VideoSteps-module_stepText__HYIV4";
var _f = "VideoSteps-module_stepsContainer__wEupz";
var _10 = "VideoSteps-module_title__r4nZH";
export { _1 as "button", _2 as "container", _3 as "desktop", _4 as "divider", _5 as "header", _6 as "mobile", _7 as "mobileStepNumber", _8 as "number", _9 as "number1", _a as "number2", _b as "number3", _c as "numberContainer", _d as "step", _e as "stepText", _f as "stepsContainer", _10 as "title" }
export default { "button": _1, "container": _2, "desktop": _3, "divider": _4, "header": _5, "mobile": _6, "mobileStepNumber": _7, "number": _8, "number1": _9, "number2": _a, "number3": _b, "numberContainer": _c, "step": _d, "stepText": _e, "stepsContainer": _f, "title": _10 }
