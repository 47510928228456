// TODO REB-95 Clean up components in web2
import {
  LoadingSpinner as LoadingSpinnerImport,
  loadingSpinnerSizes,
  notificationTypes,
  PageNotification as PageNotificationImport,
  SiteNav as SiteNavImport
} from '@animoto/components';
import evaluateFeature from '@animoto/ld-utils/evaluateFeature';
import { trackFeatureUsage, trackPage } from '@animoto/utils/analytics';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import aspectRatios from '../../../../configs/enums/aspectRatios';
import pollRenderJob from '../../../../utils/api/pollRenderJob';
import getVideoSources from '../../../../utils/getVideoSources';
import redirect from '../../../../utils/redirect';
import setupTracking from '../../utils/tracking';
import { getUserAttribute } from '../../../../utils/userWrapper';
import Player from '../../../../components/Player';
import Banner from '../Banner';
import BlogPosts from '../BlogPosts';
import VideoSteps from '../VideoSteps';
import VideoDetails from '../VideoDetails';
import ShareModal from '../ShareModal';
import OwnerToast from '../OwnerToast';
import {
  blog,
  blogStyles,
  columnWrapper,
  container,
  contentWrap,
  details,
  exploreAnimotoWrap,
  exploreBlog,
  loadingSpinner,
  videoWrapper,
  videoWrapperWrap,
  wrapper,
  landscape,
  square,
  vertical
} from './PlayApp.module.css';

const aspectRatioCssClassMap = {
  landscape,
  square,
  vertical
};

export default function PlayApp({
  aspectRatio,
  autostart,
  coverImageUrl,
  ctaTitle,
  ctaUrl,
  defaultResolution,
  description,
  displayDate,
  isMobile,
  isRegisteredUser,
  isVideoOwner,
  producerName,
  renderData,
  showSocialNetworks,
  title
}) {
  const isRebrand = evaluateFeature('rebrand', false);
  const LoadingSpinner = LoadingSpinnerImport(isRebrand);
  const PageNotification = PageNotificationImport(isRebrand);
  const SiteNav = SiteNavImport(isRebrand);

  const { video_formats : videoFormats, jobs : renderJobs } = renderData;
  const [hasVideoCompleted, setHasVideoCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sources, setSources] = useState(getVideoSources(videoFormats, defaultResolution));
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const videoPlayerRef = useRef(null);
  const isVideoRendering = !sources.length;
  const resolution = isVideoRendering ? '0p' : sources.find((source) => source.selected).label;
  const playPageUrl = window.location.href;

  if (isVideoRendering && renderJobs.length) {
    let renderJob = renderJobs.find(({ purpose }) => purpose === 'preview');
    if (!renderJob) {
      renderJob = renderJobs.find(({ state }) => state === 'rendering');
    }

    pollRenderJob(renderJob.links.self, (completedJob) => {
      setSources(getVideoSources([completedJob]));
    }, () => {
      setErrorMessage('Sorry, there was an error loading the video. Please try again later.');
    });
  }

  React.useEffect(() => {
    trackPage('/play/visitor', true);
  }, []);

  const playerSettings = {
    autostart,
    poster  : coverImageUrl,
    sources,
    preload : 'auto'
  };

  if (ctaTitle && ctaUrl) {
    playerSettings.ctaSettings = {
      title : ctaTitle,
      url   : ctaUrl
    };
  }

  if (aspectRatio === aspectRatios.SQUARE) {
    playerSettings.aspectRatio = '1:1';
  } else if (aspectRatio === aspectRatios.VERTICAL) {
    playerSettings.aspectRatio = '9:16';
  }

  function onCreatePlayer(videoPlayer) {
    videoPlayerRef.current = videoPlayer;

    videoPlayer.once('playlistComplete', () => {
      setHasVideoCompleted(true);
    });

    setupTracking(videoPlayer);
  }

  const videoClasses = classNames('s-video', videoWrapper, aspectRatioCssClassMap[aspectRatio]);

  const onClickButton = () => {
    if (!isMobile && isRegisteredUser) {
      window.open('/builder/templates');
    } else if (!isMobile && !isRegisteredUser) {
      window.open('/sign_up');
    } else if (isMobile) {
      redirect('/sign_up');
    }
  };

  const openMobileSharePopup = async () => {
    try {
      trackFeatureUsage({
        scope   : 'visitor-play-page',
        feature : 'share-mobile',
        action  : 'open'
      });

      await window.navigator.share({
        title,
        url : playPageUrl
      });

      trackFeatureUsage({
        scope   : 'visitor-play-page',
        feature : 'share-mobile',
        action  : 'close',
        detail  : 'complete'
      });
    } catch (e) {
      trackFeatureUsage({
        scope   : 'visitor-play-page',
        feature : 'share-mobile',
        action  : 'close',
        detail  : 'cancel'
      });
    }
  };

  const onOpenShareModal = () => {
    if (isMobile) {
      openMobileSharePopup();
    } else {
      setIsShareModalOpen(true);
    }
  };

  const onCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const userLinks = [
    {
      // [FAS-1320] Remove once components library is using updated Project copy
      label : 'Projects',
      URL   : '/projects'
    },
    {
      label : 'Account',
      URL   : '/account'
    },
    {
      label : 'Help',
      URL   : '/help'
    }
  ];

  const videoWrapperClasses = classNames(videoWrapperWrap, { [loadingSpinner] : isVideoRendering });

  return (
    <div className={wrapper}>
      <SiteNav
        firstName={getUserAttribute('first_name')}
        isLoggedIn={isRegisteredUser}
        lastName={getUserAttribute('last_name')}
        userLinks={userLinks}
      />
      { errorMessage && (
        <PageNotification autoHide type={notificationTypes.ERROR}>
          {errorMessage}
        </PageNotification>
      )}
      {!isMobile && (
        <ShareModal
          isOpen={isShareModalOpen}
          onCloseModal={onCloseShareModal}
          producerName={producerName}
          resolution={resolution}
          shareUrl={playPageUrl}
          showSocialNetworks={showSocialNetworks}
          videoTitle={title}
        />
      )}
      <div className={contentWrap}>
        <div className={blogStyles}>
          <div className={container}>
            <div className={videoWrapperClasses}>
              <div className={videoClasses}>
                {isVideoRendering ? (
                  <LoadingSpinner size={loadingSpinnerSizes.LARGE} />
                ) : (
                  <Player onCreatePlayer={onCreatePlayer} settings={playerSettings} />
                )}
              </div>
            </div>
            <div className={blog}>
              <BlogPosts />
            </div>
          </div>
          <div className={columnWrapper}>
            <div className={details}>
              {!isMobile && (
                <Banner
                  hasVideoCompleted={hasVideoCompleted}
                  onClick={onClickButton}
                />
              )}
              <VideoDetails
                description={description}
                displayDate={displayDate}
                isSharingEnabled={!isVideoRendering}
                onOpenShareModal={onOpenShareModal}
                producerName={producerName}
                title={title}
              />
            </div>
            <div className={exploreAnimotoWrap}>
              <div className={exploreBlog}>
                <BlogPosts />
              </div>
              <VideoSteps
                onClick={onClickButton}
              />
            </div>
          </div>
          {isMobile && (
            <Banner
              hasVideoCompleted={hasVideoCompleted}
              onClick={onClickButton}
            />
          )}
        </div>
      </div>
      {isVideoOwner && <OwnerToast />}
    </div>
  );
}

PlayApp.propTypes = {
  aspectRatio       : PropTypes.string.isRequired,
  autostart         : PropTypes.bool,
  coverImageUrl     : PropTypes.string,
  ctaTitle          : PropTypes.string,
  ctaUrl            : PropTypes.string,
  defaultResolution : PropTypes.string,
  description       : PropTypes.string,
  displayDate       : PropTypes.string.isRequired,
  isMobile          : PropTypes.bool.isRequired,
  isRegisteredUser  : PropTypes.bool.isRequired,
  isVideoOwner      : PropTypes.bool.isRequired,
  producerName      : PropTypes.string,
  renderData        : PropTypes.shape({
    jobs          : PropTypes.array, // eslint-disable-line react/forbid-prop-types
    video_formats : PropTypes.array // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  showSocialNetworks : PropTypes.bool,
  title              : PropTypes.string.isRequired
};

PlayApp.defaultProps = {
  autostart          : false,
  coverImageUrl      : '',
  ctaTitle           : '',
  ctaUrl             : '',
  defaultResolution  : '',
  description        : '',
  producerName       : '',
  showSocialNetworks : false
};
